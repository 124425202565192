<template>
    <div class="auth-wrapper auth-v2">
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   rounded="sm">


            <b-row class="auth-inner m-0">

                <!-- Brand logo-->
                <b-link class="brand-logo" to="/">
                    <vuexy-logo />
                </b-link>
                <!-- /Brand logo-->
                <!-- Left Text-->
                <b-col lg="6"
                       class="d-none d-lg-flex align-items-center p-5">
                    <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                        <b-img fluid
                               :src="imgUrl"
                               alt="Forgot password V2" />
                    </div>
                </b-col>
                <!-- /Left Text-->
                <!-- Forgot password-->
                <b-col lg="6"
                       class="d-flex align-items-center auth-bg px-2 p-lg-5">

                    <b-col sm="8"
                           md="6"
                           lg="12"
                           class="px-xl-2 mx-auto">
                        <b-card-title class="mb-1">
                            Forgot Password? 🔒
                        </b-card-title>
                        <b-card-text class="mb-2">
                            Enter your email and we'll send you instructions to reset your password
                        </b-card-text>

                        <b-card-text class="mb-2" v-if="error">
                            <div class="error" v-html="error">
                                {{ error }}
                            </div>
                        </b-card-text>

                        <!-- form -->
                        <validation-observer ref="simpleRules">
                            <b-form class="auth-forgot-password-form mt-2"
                                    @submit.prevent="forgotPassForm">
                                <b-form-group label="Email"
                                              label-for="useremail">
                                    <validation-provider #default="{ errors }"
                                                         name="Email"
                                                         rules="required|email">
                                        <b-form-input id="useremail"
                                                      v-model="userEmail"
                                                      :state="errors.length > 0 ? false:null"
                                                      name="useremail"
                                                      placeholder="john@example.com" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>

                                <b-button type="submit"
                                          variant="primary"
                                          block>
                                    Send password reset link
                                </b-button>
                            </b-form>
                        </validation-observer>

                        <p class="text-center mt-2">
                            <b-link :to="{name:'auth-login'}">
                                <feather-icon icon="ChevronLeftIcon" /> Back to login
                            </b-link>
                        </p>
                    </b-col>
                </b-col>
                <!-- /Forgot password-->
            </b-row>
        </b-overlay>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BOverlay,
    } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import { required, email } from '@validations'
    import store from '@/store/index'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {
        components: {
            VuexyLogo,
            BRow,
            BCol,
            BLink,
            BImg,
            BForm,
            BButton,
            BFormGroup,
            BFormInput,
            BCardTitle,
            BCardText,
            ValidationProvider,
            ValidationObserver,
            BOverlay
        },
        data() {
            return {
                loading: false,
                variant: 'light',
                opacity: 0.85,
                blur: '2px',
                error: null,
                userEmail: '',
                sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
                // validation
                required,
                email,
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            forgotPassForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.error = null;
                        useJwt.forgotpass({
                            email: this.userEmail,
                        })
                            .then(response => {
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Please check your email to reset a new password.',
                                        icon: 'EditIcon',
                                        variant: 'success',
                                    },
                                },
                                    { timeout: 9000 }
                                )
                                this.loading = false;
                            })
                            .catch(error => {
                                //this.$refs.loginForm.setErrors(error.response.data.error)
                                this.error = error.response.data.ModelState.Response;
                                this.loading = false;
                            })
                    }
                })
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';

    .error {
        color: red;
    }

    .b-overlay-wrap {
        width: 100%;
    }

    .brand-logo svg {
        height: 70px;
    }
</style>
